
import registriesList from './registries.json'
import RegistryNamesMappings from './registryNameMappings'
import recentOperationsDatabase from '@/services/offline/registries/registriesRecentOperations.database.js'

class registriesManagment {
  constructor() {
    this.registries = registriesList
    this._calculateRegistriesText()
  }

  list() {
    return Promise.resolve(this.registries)
  }

  lastRecentOperationsList(number = 8) {
    return new Promise((resolve, reject) => {
      recentOperationsDatabase.getLastOperations(number).then((operations) => {
        resolve(operations)
      }).catch((err) => {
        reject(err)
      })
    })
  }


  _calculateRegistriesText() {
    for (let i = 0; i < this.registries.length; i++) {
      this.registries[i].text = RegistryNamesMappings.getHumanName(this.registries[i].name)
      this.registries[i].icon = RegistryNamesMappings.getRegistryIcon(this.registries[i].name)
    }
  }
}

export default new registriesManagment()